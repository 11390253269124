<template>
  <div class="occupancy-analytics">
    <Loader :loading="showLoader" />

    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Occupancy Analytics' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container fluid class="py-6">
      <template v-if="showCharts">
        <transition name="fade">
          <v-row justify="center" v-if="!showLoader">
            <!-- Filters -->
            <v-col cols="12" sm="4">
              <v-select v-model="selectedTimeRange" :items="timeRangeOptions" label="Select Time Range" dense outlined
                item-value="value" item-title="text" class="mb-4" @change="handleFilterChange"></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="selectedCategory" :items="poiCategories" item-title="name" item-value="name"
                return-object label="POI Category" dense outlined class="mb-4" @change="handleFilterChange"></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select v-model="selectedFloor" :items="poiFloors" item-title="name" item-value="name" return-object
                label="Floor" dense outlined class="mb-4" @change="handleFilterChange"></v-select>
            </v-col>
          </v-row>
        </transition>

        <transition name="fade" mode="out-in">
          <v-row justify="center" v-if="!showLoader">
            <v-col cols="12">
              <!-- Heatmap Chart -->
              <v-card class="pa-4 mb-4 heatmap-card">
                <apexchart ref="heatmapChart" width="100%" height="350" type="heatmap" :options="heatmapChartOptions"
                  :series="heatmapChartData"></apexchart>
              </v-card>
            </v-col>
          </v-row>
        </transition>

        <transition name="fade" mode="out-in">
          <PoiHeatMap v-if="!showLoader && showPoiHeatMap" :reservations="poiHeatmapData"
            :selectedTimeRange="selectedTimeRange" :selectedCategory="selectedCategory"
            :selectedFloor="selectedFloor" />
        </transition>

        <transition name="fade" mode="out-in">
          <v-row justify="center" v-if="!showLoader && showTreeMap">
            <!-- TreeMap Chart -->
            <v-col cols="12">
              <ReservationsTreeMap :reservations="reservations" :selected-time-range="selectedTimeRange"
                :selected-category="selectedCategory" :selected-floor="selectedFloor" />
            </v-col>
          </v-row>
        </transition>

        <transition name="fade" mode="out-in">
          <v-row justify="center" v-if="!showLoader && showPyramidChart">
            <!-- Pyradmid Chart -->
            <v-col cols="12">
              <ReservationsPyramidChart :reservations="reservations" :selected-time-range="selectedTimeRange"
                :selected-category="selectedCategory" :selected-floor="selectedFloor" />
            </v-col>
          </v-row>
        </transition>

        <transition name="fade" mode="out-in">
          <v-row justify="center" v-if="!showLoader && showDonutChart && selectedCategory.name === 'All Categories'">
            <!-- Donut Chart -->
            <v-col cols="12">
              <ReservationsDonutChart :reservations="reservations" :selected-time-range="selectedTimeRange"
                :selected-category="selectedCategory" :selected-floor="selectedFloor" />
            </v-col>
          </v-row>
        </transition>
      </template>

      <template v-else>
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <p class="rotate-message">Please rotate your device to landscape mode to view analytics.</p>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, watch } from 'vue'
import Loader from '@/components/general/Loader.vue'
import VueApexCharts from 'vue3-apexcharts'
import ReservationsTreeMap from '@/components/general/ChildComponents/ReservationsTreeMap.vue'
import ReservationsPyramidChart from '@/components/general/ChildComponents/ReservationsPyramidChart.vue'
import lodash from 'lodash'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { categories } from '@/dummydata/categories.js'
import { floors } from '@/dummydata/floors.js'
import moment from 'moment'
import ReservationsDonutChart from '@/components/general/ChildComponents/ReservationsDonutChart.vue'
import PoiHeatMap from '@/components/general/ChildComponents/PoiHeatMap.vue'
import { Device } from '@capacitor/device'
import { ScreenOrientation } from '@capacitor/screen-orientation'

export default defineComponent({
  name: 'OccupancyAnalytics',
  components: {
    Loader,
    apexchart: VueApexCharts,
    ReservationsTreeMap,
    ReservationsPyramidChart,
    ReservationsDonutChart,
    PoiHeatMap,
  },
  setup() {
    const showLoader = ref(true)
    const logo = ref('')
    const heatmapChart = ref(null)
    const showPyramidChart = ref(false)
    const showDonutChart = ref(false)
    const showPoiHeatMap = ref(false)
    const poiHeatmapData = ref([])

    const reservations = ref([])
    const showTreeMap = ref(false)
    const isMobile = ref(false)
    const showCharts = ref(true)

    const tomorrow = moment().add(1, 'day')
    const isTomorrowWeekend = tomorrow.isoWeekday() === 6 || tomorrow.isoWeekday() === 7

    const timeRangeOptions = ref([
      { text: 'Today', value: 'today' },
      ...(isTomorrowWeekend ? [] : [{ text: 'Tomorrow', value: 'tomorrow' }]),
      { text: 'Yesterday', value: 'yesterday' },
      { text: 'This Week', value: 'thisWeek' },
      { text: 'Last Week', value: 'lastWeek' },
      { text: 'Current Month', value: 'currentMonth' },
      { text: 'Last Month', value: 'lastMonth' },
      { text: 'Next Month', value: 'nextMonth' },
    ])

    const poiCategories = ref([{ name: 'All Categories' }, ...categories.flat()])
    const poiFloors = ref([{ name: 'All Floors' }, ...floors.flat()])

    const selectedTimeRange = ref('today')
    const selectedCategory = ref(poiCategories.value[0])
    const selectedFloor = ref(poiFloors.value[0])

    const goBack = () => {
      window.history.back()
    }

    const heatmapChartOptions = ref({
      chart: {
        id: 'heatmap-chart',
        toolbar: {
          show: true,
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        type: 'category',
        title: {
          text: 'POI Category',
        },
      },
      yaxis: {
        title: {
          text: 'Time Slots',
        },
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 3,
                color: '#00A100',
                name: 'Low',
              },
              {
                from: 4,
                to: 7,
                color: '#FFB200',
                name: 'Medium',
              },
              {
                from: 8,
                to: 100,
                color: '#FF0000',
                name: 'High',
              },
            ],
          },
        },
      },
    })

    const heatmapChartData = ref([])

    const getNextMonthDates = () => {
      const startDate = moment().add(1, 'month').startOf('month')
      const endDate = moment().add(1, 'month').endOf('month')
      const totalDays = endDate.diff(startDate, 'days') + 1
      return Array.from({ length: totalDays }, (_, i) => startDate.clone().add(i, 'days').format('DD-MM')).filter(date => {
        const dayOfWeek = moment(date, 'DD-MM').isoWeekday()
        return dayOfWeek !== 6 && dayOfWeek !== 7
      })
    }

    const getMonthDates = (startDate, endDate) => {
      const totalDays = endDate.diff(startDate, 'days') + 1
      return Array.from({ length: totalDays }, (_, i) => startDate.clone().add(i, 'days').format('DD-MM')).filter(date => {
        const dayOfWeek = moment(date, 'DD-MM').isoWeekday()
        return dayOfWeek !== 6 && dayOfWeek !== 7
      })
    }

    const checkDevice = async () => {
      const info = await Device.getInfo()
      console.log('Device Info:', info) // Log device information for debugging
      isMobile.value = info.operatingSystem === 'ios' || info.operatingSystem === 'android'
    }

    const handleOrientationChange = async () => {
      const orientation = await ScreenOrientation.orientation()
      console.log('Orientation:', orientation) // Log orientation information for debugging
      if (isMobile.value && orientation.type.includes('portrait')) {
        console.log('Not Mobile Device')
        showCharts.value = false
      } else {
        console.log('Mobile Device')
        showCharts.value = true
      }
    }

    const filterReservations = () => {
      let filteredReservations = reservations.value || []
      let startDate, endDate
      switch (selectedTimeRange.value) {
        case 'today':
          startDate = moment().startOf('day')
          endDate = moment().endOf('day')
          break
        case 'yesterday':
          startDate = moment().subtract(1, 'day').startOf('day')
          endDate = moment().subtract(1, 'day').endOf('day')
          break
        case 'tomorrow':
          startDate = moment().add(1, 'day').startOf('day')
          endDate = moment().add(1, 'day').endOf('day')
          break
        case 'thisWeek':
          startDate = moment().startOf('week')
          endDate = moment().endOf('week')
          break
        case 'lastWeek':
          startDate = moment().subtract(1, 'week').startOf('week')
          endDate = moment().subtract(1, 'week').endOf('week')
          break
        case 'currentMonth':
          startDate = moment().startOf('month')
          endDate = moment().endOf('month')
          break
        case 'lastMonth':
          startDate = moment().subtract(1, 'month').startOf('month')
          endDate = moment().subtract(1, 'month').endOf('month')
          break
        case 'nextMonth':
          startDate = moment().add(1, 'month').startOf('month')
          endDate = moment().add(1, 'month').endOf('month')
          break
      }

      filteredReservations = filteredReservations.filter(reservation => {
        const reservationDate = moment(reservation.startTime)
        return reservationDate.isBetween(startDate, endDate, null, '[]')
      })
      if (selectedCategory.value.name !== 'All Categories') {
        filteredReservations = filteredReservations.filter(reservation => reservation.poiCategory === selectedCategory.value.name)
      }

      if (selectedFloor.value.name !== 'All Floors') {
        filteredReservations = filteredReservations.filter(reservation => reservation.floorName === selectedFloor.value.name)
      }
      return filteredReservations
    }

    const processHeatmapData = () => {
      const filteredReservations = filterReservations()
      if (!filteredReservations || filteredReservations.length === 0) {
        heatmapChartData.value = []
        return
      }

      let yAxisLabels, dataByCategoryAndTimeOrDate
      let startDate, endDate

      if (['today', 'yesterday', 'tomorrow'].includes(selectedTimeRange.value)) {
        yAxisLabels = Array.from({ length: 15 }, (_, i) => `${i + 6}:00`)
        dataByCategoryAndTimeOrDate = {}

        poiCategories.value.forEach(category => {
          if (category.name !== 'All Categories') {
            dataByCategoryAndTimeOrDate[category.name] = yAxisLabels.map(time => ({ x: time, y: 0 }))
          }
        })

        filteredReservations.forEach(reservation => {
          const category = reservation.poiCategory
          const startTime = moment(reservation.startTime).format('H:00')

          const categoryData = dataByCategoryAndTimeOrDate[category]
          if (categoryData) {
            const timeSlot = categoryData.find(slot => slot.x === startTime)
            if (timeSlot) {
              timeSlot.y += 1
            }
          }
        })
      } else {
        startDate = moment(filteredReservations[0]?.startTime).startOf('day')
        endDate = moment(filteredReservations[filteredReservations.length - 1]?.endTime).endOf('day')
        yAxisLabels = selectedTimeRange.value === 'nextMonth' ? getNextMonthDates() : getMonthDates(startDate, endDate)
        dataByCategoryAndTimeOrDate = {}

        poiCategories.value.forEach(category => {
          if (category.name !== 'All Categories') {
            dataByCategoryAndTimeOrDate[category.name] = yAxisLabels.map(date => ({ x: date, y: 0 }))
          }
        })

        filteredReservations.forEach(reservation => {
          const category = reservation.poiCategory
          const reservationDate = moment(reservation.startTime).format('DD-MM')

          const categoryData = dataByCategoryAndTimeOrDate[category]
          if (categoryData) {
            const dateSlot = categoryData.find(slot => slot.x === reservationDate)
            if (dateSlot) {
              dateSlot.y += 1
            }
          }
        })
      }

      heatmapChartData.value = Object.keys(dataByCategoryAndTimeOrDate).map(category => ({
        name: category,
        data: dataByCategoryAndTimeOrDate[category],
      }))

      heatmapChartOptions.value.yaxis.title.text =
        selectedTimeRange.value === 'today' || selectedTimeRange.value === 'yesterday' || selectedTimeRange.value === 'tomorrow'
          ? 'Time Slots'
          : 'Dates'
    }

    const handleFilterChange = () => {
      showLoader.value = true
      showTreeMap.value = false
      showPyramidChart.value = false
      showDonutChart.value = false
      showPoiHeatMap.value = false

      setTimeout(() => {
        showPoiHeatMap.value = selectedCategory.value.name !== 'All Categories'
        if (showPoiHeatMap.value) {
          poiHeatmapData.value = filterReservations() // Pass filtered data
        }
        updateChart()
      }, 500)
    }

    const updateChart = () => {
      processHeatmapData()
      if (heatmapChart.value) {
        heatmapChart.value.updateSeries(heatmapChartData.value)
      }
      showLoader.value = false
      showTreeMap.value = true
      showPyramidChart.value = true
      showDonutChart.value = true
    }
    onMounted(async () => {
      try {
        showLoader.value = true
        logo.value = await getToolbarLogoImage()

        const reservationsData = await import('@/dummydata/reservations.json')
        console.log(lodash.filter(reservation => reservation.poiCategory === 'undefined'))
        checkDevice()
        await handleOrientationChange()

        ScreenOrientation.addListener('screenOrientationChange', handleOrientationChange)
        reservations.value = reservationsData.reservations

        updateChart() // Initial chart update for the heatmap
        showTreeMap.value = true
        showPyramidChart.value = true
        showDonutChart.value = true
      } catch (error) {
        console.error('Error loading data', error)
      } finally {
        showLoader.value = false
      }
    })

    watch([selectedTimeRange, selectedCategory, selectedFloor], handleFilterChange)

    return {
      logo,
      showLoader,
      heatmapChartOptions,
      heatmapChartData,
      timeRangeOptions,
      poiCategories,
      poiFloors,
      selectedTimeRange,
      selectedCategory,
      selectedFloor,
      reservations,
      showTreeMap,
      showPyramidChart,
      showDonutChart,
      showPoiHeatMap,
      poiHeatmapData,
      showCharts,
      goBack,
    }
  },
})
</script>

<style scoped>
.occupancy-analytics {
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.heatmap-card,
.treemap-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

.v-select .v-input__control {
  font-weight: 600;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.v-select .v-input__control:hover {
  color: #000;
}

.v-select .v-input__control:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  outline: none;
}

.rotate-message {
  font-size: 1.5rem;
  color: #555;
  font-weight: 600;
  margin-top: 20%;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
</style>
