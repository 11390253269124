<template>
  <div class="poi-details">
    <Loader :loading="showLoader" />

    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">mdi-arrow-left-bold-circle</v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">{{ getPoiName() }}</v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <MapView ref="mapView" />

    <v-container class="custom-container" fluid style="margin: 0px; padding: 0px; width: 100%">
      <div class="main-container">
        <v-row no-gutters align="start">
          <v-col :cols="12" lg="4" md="6" sm="5">
            <v-card class="mx-auto" max-width="374" elevation="5">
              <v-img :src="poiImage" height="250" cover></v-img>
            </v-card>
          </v-col>
          <v-col :cols="12" lg="8" md="6" sm="7" class="pa-2 mt-3" style="display: flex; align-items: flex-start">
            <v-card outlined elevation="5" class="pa-6" style="width: 100%; margin-left: 0">
              <v-card-title style="font-weight: bold; font-size: 1rem">{{ poi?.name }}</v-card-title>
              <v-card-subtitle style="text-align: start; margin-top: 0.2rem">
                <v-icon>mdi-office-building-marker-outline</v-icon>
                <span class="text-h8 font-weight-light">
                  {{ poiCategory?.name }}
                </span>
              </v-card-subtitle>
              <v-card-subtitle style="text-align: start; margin-top: 0.8rem">
                <v-icon>mdi-blur</v-icon>
                {{ poi?.floor?.name }}
              </v-card-subtitle>
              <v-card-actions style="text-align: start">
                <button class="show-on-map-button" @click="openMap()">{{ $t('booking.show-map') }}</button>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-card
            elevation="5"
            v-if="poi?.hasHardware || poi?.hasEntrances"
            style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem; padding: 1.5rem"
            width="100%"
          >
            <!-- First Row: Hardware Icons with Shadow -->
            <v-card-text class="d-flex justify-start card-content" v-if="poi?.hasHardware">
              <v-btn
                v-if="poi.LIGHT?.length"
                icon
                style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
                class="ma-2"
              >
                <v-icon color="black">mdi-lightbulb-on-outline</v-icon>
              </v-btn>
              <v-btn
                v-if="poi.BLINDS?.length"
                icon
                style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
                class="ma-2"
              >
                <v-icon color="black">mdi-blinds</v-icon>
              </v-btn>
              <v-btn
                v-if="poi.VENTILATOR?.length"
                icon
                style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
                class="ma-2"
              >
                <v-icon color="black">mdi-fan</v-icon>
              </v-btn>
              <v-btn
                v-if="poi.HEATER?.length"
                icon
                style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); background-color: white; border-radius: 50%"
                class="ma-2"
              >
                <v-icon color="black">mdi-thermometer</v-icon>
              </v-btn>
            </v-card-text>

            <!-- Second Row: Elevator Information -->
            <v-row no-gutters align="center" justify="space-between" class="mt-4 d-flex" v-if="poi?.hasEntrances">
              <v-col class="d-flex align-center" cols="auto">
                <v-icon color="purple" size="32" class="mr-2">mdi-elevator</v-icon>
                <span>{{ poi.entrances[0]?.elevator?.name }}</span>
              </v-col>
            </v-row>

            <!-- Third Row: Entrance Information with Chips -->
            <v-row no-gutters align="center" justify="start" class="mt-4 d-flex flex-wrap" v-if="poi?.entrances?.length">
              <v-col class="d-flex align-center" cols="auto">
                <v-icon color="gray" size="32" class="mr-2">mdi-door</v-icon>
                <div>
                  <span>Entrances:</span>
                  <v-chip v-for="(entrance, index) in poi.entrances" :key="index" class="ma-1" small outlined color="primary">
                    {{ entrance.name }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <!-- <v-card v-if="poi?.description" elevation="2"
          style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem" width="100%">
          <v-card-text>
            <v-card-title style="font-weight: bold">Description</v-card-title>
            <v-card-subtitle style="text-align: start">
              <span v-if="!readMore" v-html="trimmedDescription"></span>
              <span v-else v-html="fullDescription"></span>
              <v-btn text small color="primary" v-if="longDescription" @click="toggleReadMore" style="margin-top: 1rem">
                {{ readMore ? 'Read Less' : 'Read More' }}
              </v-btn>
            </v-card-subtitle>
          </v-card-text>
        </v-card> -->

          <v-dialog v-model="showIncidents" max-width="500">
            <v-card elevation="5" style="border-radius: 10px; box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); margin-top: 1rem">
              <v-card-title class="text-h5 grey lighten-2">
                Current Incidents
                <v-btn icon @click="showIncidents = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-list dense>
                  <v-list-item v-for="(incident, index) in sortedIncidents" :key="index" three-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ incident?.incidentCategoryName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ formatDate(incident.creationTime) }}</v-list-item-subtitle>
                      <v-list-item-subtitle :style="{ color: getIncidentColor(incident.state) }">
                        {{ incident.state }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-card class="time-slot-container" v-if="!isRestroom" elevation="5" width="100%">
            <v-row class="header-section">
              <!-- <v-btn text small dark @click="availabilityDialog = true">
                Calender
                <v-icon right dark>mdi-calendar</v-icon>
              </v-btn> -->

              <v-btn :size="isSmallScreen ? 'small' : 'large'" elevation="0" variant="outlined" @click="availabilityDialog = true">
                {{ moment(selectedDate).format('DD-MM-YYYY') }}
                <v-icon right dark>mdi-calendar</v-icon>
              </v-btn>
              <v-btn text :size="isSmallScreen ? 'small' : 'large'" dark color="black" @click="goToBooking">{{ $t('booking.schedule-now') }}</v-btn>
            </v-row>

            <div class="availability-section">
              <div class="availability-title">
                <h4>{{ formattedDate }} {{ $t('booking.availability') }}</h4>
                <div class="arrow-controls">
                  <v-btn
                    :disabled="isToday"
                    class="ma-1"
                    @click="previousDay"
                    elevation="2"
                    fab
                    icon
                    :size="isSmallScreen ? 'x-small' : 'large'"
                    style="background-color: black"
                  >
                    <v-icon color="white">mdi-arrow-left-bold</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-1"
                    @click="nextDay"
                    elevation="2"
                    fab
                    icon
                    :size="isSmallScreen ? 'x-small' : 'large'"
                    style="background-color: black"
                  >
                    <v-icon color="white">mdi-arrow-right-bold</v-icon>
                  </v-btn>
                </div>
              </div>
              <v-progress-linear color="red lighten-2" buffer-value="0" stream v-if="showDateChangeLoader"></v-progress-linear>
              <TimeSlot v-else :selectedDate="selectedDate" :bookings="bookings" />
            </div>

            <v-dialog v-model="availabilityDialog" persistent max-width="300px">
              <v-card style="background-color: #1a202c">
                <v-card-title></v-card-title>
                <v-card-text>
                  <DatePicker
                    :max-date="maxDate"
                    ref="datePicker"
                    color="red"
                    :disabled-dates="disabledDates"
                    is-dark
                    v-model="selectedDate"
                    mode="date"
                  ></DatePicker>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn plain color="white" style="background-color: #ff5252" text @click="discardDate">{{ $t('office.cancel') }}</v-btn>
                  <v-btn plain style="background-color: #4caf50; color: white" @click="confirmDate">{{ $t('office.ok') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- <v-container class="d-flex justify-center align-center" fill-height>
              <v-btn color="black" width="20%" style="border-radius: 2px; color: white"
                @click="goToBooking">Book</v-btn>
            </v-container> -->
            <!-- <TimeSlot v-if="!showLoader" :startTime="new Date(slotsStart)" :endTime="new Date(slotsEnd)"
              :bookings="bookings" :freeBookings="freeBookings"
              :class="{ 'disabled-timeslot': showCalendarModal || showIncidents }" /> -->
          </v-card>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { ref, onMounted, computed, onUnmounted } from 'vue'
  import { useRouter } from 'vue-router'
  import lodash from 'lodash'
  import { debounce } from 'lodash'
  import moment from 'moment'
  import momentTZ from 'moment-timezone'
  import Loader from '@/components/general/Loader.vue'
  import { DatePicker } from 'v-calendar'
  import MapView from '@/components/general/MapView.vue'
  import TimeSlot from '@/components/general/ChildComponents/TimeSlot.vue'
  import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
  import { useOAuthService } from '@/services/OAuthService'

  import {
    getFile,
    getFloorById,
    getPoiCategoryById,
    getPoiById,
    getBuildings,
    isStaingOrQa,
    getReservationsByPoi,
  } from '@/controllers/BaseController'
  import { getHardwareByType, getElevators } from '@/controllers/BackboneAPI'

  export default {
    name: 'PoiDetails',
    components: {
      Loader,
      TimeSlot,
      DatePicker,
      MapView,
    },
    setup() {
      const router = useRouter()
      const showLoader = ref(true)
      const OAuthService = useOAuthService()
      const poi = ref({})
      const logo = ref('')
      const selectedBuilding = ref({})
      const windowWidth = ref(window.innerWidth)
      const isSmallScreen = computed(() => windowWidth.value < 800)
      const mapView = ref(null)
      const poiCategory = ref({})
      const bookings = ref([])
      const freeBookings = ref([])
      const showIncidents = ref(false)
      const readMore = ref(false)
      const longDescription = ref(false)
      const originalPoi = ref({})
      const trimmedDescription = ref('')
      const disabledDates = ref([
        {
          repeat: {
            weekdays: [7, 1], // 1 is Monday, 7 is Sunday
          },
        },
      ])
      const fullDescription = ref('')
      const slotsStart = ref(new Date())
      const slotsEnd = ref(new Date())
      const showCalendarModal = ref(false)
      const availabilityDialog = ref(false)
      const selectedDate = ref(new Date())
      const poiImage = ref('')
      const hasHardware = ref(false)
      const isRestroom = ref(false)
      const userProfile = ref({})

      const goBack = () => router.back()

      const maxDate = computed(() => {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 57)
      })

      const isToday = computed(() => {
        return moment(selectedDate.value).isSame(moment(), 'day')
      })

      const formattedDate = computed(() => {
        return moment(selectedDate.value).format('DD MMMM YYYY')
      })

      const openDatePicker = () => {
        availabilityDialog.value = true
      }

      const discardDate = () => {
        availabilityDialog.value = false
        selectedDate.value = new Date()
      }

      const confirmDate = async () => {
        availabilityDialog.value = false
        await handleAvailability()
      }

      const handleAvailability = async () => {
        try {
          let start = moment(selectedDate.value).startOf('day').toDate()
          let end = moment(selectedDate.value).endOf('day').toDate()
          let poiBookings = await getReservationsByPoi(poi.value.id, start, end)
          poiBookings.forEach(reservation => {
            let creatorNameFirstWord = reservation?.creatorName ? reservation.creatorName.split(' ')[0].toLowerCase() : null
            let creatorEmailPart = reservation.creatorEmail.split('@')[0].toLowerCase()
            let emailParts = creatorEmailPart.split('.')
            let name
            if (creatorNameFirstWord && emailParts.includes(creatorNameFirstWord)) {
              name = emailParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
            } else {
              name = emailParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ')
            }
            let start = moment(reservation.startTime).utc().toDate()
            let end = moment(reservation.endTime).utc().toDate()
            return {
              start: moment(start).format('HH:mm'),
              end: moment(end).format('HH:mm'),
              own: reservation.creatorEmail === userProfile.value.email,
              name: name,
              categoryName: poiCategory.value.name,
            }
          })
          bookings.value = poiBookings
        } catch (error) {
          console.error(error)
          showLoader.value = false
        } finally {
          showLoader.value = false
        }
      }

      const getPoiName = () => poi.value.name

      const fetchPoiImage = async category => {
        let image = ''
        if (lodash.some(category.files.entities, { type: 'image' })) {
          const imageId = category.files.entities.find(file => file.type === 'image').id
          const blob = await getFile(imageId)
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = () => {
            image = reader.result
            poiImage.value = image
          }
        } else {
          poiImage.value = 'https://via.placeholder.com/300' // Fallback image
        }
      }

      const openMap = () => {
        let BUILDING = JSON.parse(JSON.stringify(selectedBuilding.value))
        let Category = JSON.parse(JSON.stringify(poiCategory.value))
        let POI = lodash.cloneDeepWith(poi.value, value => (typeof value === 'object' && new WeakSet().has(value) ? undefined : undefined))
        mapView.value.openMap(BUILDING, [POI], [Category])
      }

      const goToBooking = () => {
        router.push({
          name: 'createManageBooking',
          params: {
            building_id: poi.value.buildingId,
            category_id: poi.value.poiCategories.ids[0],
            poi_id: poi.value.id,
          },
        })
      }

      const toggleReadMore = () => {
        readMore.value = !readMore.value
      }

      const nextDay = () => {
        try {
          selectedDate.value = moment(selectedDate.value).add(1, 'days').toDate()
          handleAvailability()
        } catch (error) {
          console.error(error)
        }
      }

      const previousDay = () => {
        try {
          selectedDate.value = moment(selectedDate.value).subtract(1, 'days').toDate()
          handleAvailability()
        } catch (error) {
          console.error(error)
        }
      }

      const getAvailabiltyText = () => {
        const today = moment().format('YYYY-MM-DD')
        const givenDate = moment(slotsStart.value).format('YYYY-MM-DD')
        const isToday = today === givenDate
        return isToday ? 'Current Availability' : `${moment(slotsStart.value).format('DD MMMM YYYY')} Availability`
      }

      const formatDate = date => moment(date).format('DD.MM.YYYY HH:mm')
      const getIncidentColor = state => {
        if (state === 'Open') return 'orange'
        if (state === 'Done') return 'green'
        return '#0096ff'
      }
      let incidents = ref([])
      const sortedIncidents = computed(() => lodash.orderBy(incidents.value, ['incidentCategoryName'], ['asc']))

      onMounted(async () => {
        const resizeListener = debounce(() => (windowWidth.value = window.innerWidth), 200)
        window.addEventListener('resize', resizeListener)
        try {
          logo.value = getToolbarLogoImage()
          userProfile.value = await OAuthService.getUserProfile()
          const fetchedPoi = await getPoiById(router.currentRoute.value.params.poi_id)
          let isPIA = await isStaingOrQa()
          originalPoi.value = fetchedPoi
          let buildings = await getBuildings()
          selectedBuilding.value = buildings.find(building => building.id === fetchedPoi.buildingId)
          const fetchedCategory = await getPoiCategoryById(fetchedPoi.poiCategories.ids[0])
          if (
            fetchedCategory.name?.toLowerCase()?.includes('rest') ||
            fetchedCategory.name?.toLowerCase()?.includes('toilet') ||
            fetchedCategory.name?.toLowerCase()?.includes('wc')
          ) {
            isRestroom.value = true
          }

          let floor = await getFloorById(fetchedPoi.floors.ids[0])
          fetchedPoi['floor'] = floor
          const localTimezone = 'Europe/Berlin'
          let today = moment().tz(localTimezone).format('YYYY-MM-DD')
          if (isPIA) {
            fetchedPoi.availableFrom = '06:00'
            fetchedPoi.availableUntil = '20:00'
          }
          fetchedPoi.availableFrom = momentTZ
            .tz(`${today} ${fetchedPoi.availableFrom}`, 'YYYY-MM-DD HH:mm', localTimezone)
            .utc()
            .add(2, 'hours')
            .toDate()
          fetchedPoi.availableUntil = momentTZ
            .tz(`${today} ${fetchedPoi.availableUntil}`, 'YYYY-MM-DD HH:mm', localTimezone)
            .utc()
            .add(2, 'hours')
            .toDate()

          slotsStart.value = fetchedPoi.availableFrom
          slotsEnd.value = fetchedPoi.availableUntil
          if (fetchedPoi?.configs['room-control'] !== undefined && fetchedPoi?.configs['room-control'] === 'true') {
            const hardwareTypes = ['BLINDS', 'VENTILATOR', 'LIGHT', 'HEATER']
            const hardwarePromises = hardwareTypes.map(type => getHardwareByType(fetchedPoi.buildingId, type).catch(() => []))
            const hardwareArrays = await Promise.all(hardwarePromises)
            const hardwareData = Object.fromEntries(hardwareTypes.map((type, index) => [type, hardwareArrays[index]]))
            fetchedPoi['LIGHT'] = hardwareData['LIGHT'].filter(light => light.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['VENTILATOR'] = hardwareData['VENTILATOR'].filter(ventilator => ventilator.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['HEATER'] = hardwareData['HEATER'].filter(heater => heater.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['BLINDS'] = hardwareData['BLINDS'].filter(blind => blind.configs?.['poi-id'] === fetchedPoi.id)
            fetchedPoi['hasHardware'] = true
          }
          let elevators = await getElevators(fetchedPoi.buildingId)
          let entrances = []
          elevators.forEach((elevator, index) => {
            let ENTRANCES = elevator.entrances
            ENTRANCES.forEach(entrance => {
              if (entrance?.location?.floorId === floor.id) {
                entrance['elevator'] = elevator
                entrances.push(entrance)
              }
            })
          })
          fetchedPoi['entrances'] = entrances
          if (entrances?.length > 0) {
            fetchedPoi['hasEntrances'] = true
          }

          poi.value = fetchedPoi
          poiCategory.value = fetchedCategory

          // Fetch and set POI image
          await fetchPoiImage(fetchedCategory)

          // Set description text
          if (poi.value.description.length > 100) {
            longDescription.value = true
            trimmedDescription.value = poi.value.description.substring(0, 100) + '... '
          }
          fullDescription.value = poi.value.description
          handleAvailability()
          // Additional setup logic as needed...
        } catch (error) {
          console.error(error)
        } finally {
          onUnmounted(() => window.removeEventListener('resize', resizeListener))
        }
      })

      return {
        goBack,
        getPoiName,
        poiImage,
        openMap,
        poi,
        goToBooking,
        readMore,
        longDescription,
        toggleReadMore,
        trimmedDescription,
        fullDescription,
        showIncidents,
        formatDate,
        getIncidentColor,
        sortedIncidents,
        showCalendarModal,
        getAvailabiltyText,
        mapView,
        slotsStart,
        slotsEnd,
        originalPoi,
        disabledDates,
        selectedBuilding,
        isRestroom,
        showLoader,
        availabilityDialog,
        selectedDate,
        moment,
        maxDate,
        handleAvailability,
        openDatePicker,
        discardDate,
        confirmDate,
        bookings,
        freeBookings,
        incidents,
        logo,
        isToday,
        nextDay,
        previousDay,
        formattedDate,
        hasHardware,
        isSmallScreen,
        userProfile,
        OAuthService,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .poi-details {
    padding: 1rem;

    .custom-toolbar {
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      color: #333;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
    }

    .custom-toolbar-title {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 1.5rem;
      color: #333;
      margin-left: 10px;
      /* Spacing between logo and title */
    }

    .toolbar-icon {
      height: 30px;
    }

    .custom-toolbar-btn {
      color: rgb(67, 167, 249);
      margin-right: 8px;
    }

    .disabled-timeslot {
      pointer-events: none;
      opacity: 0.6;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .modal-content {
      background: #1a202c;
      padding: 20px;
      border-radius: 10px;
      position: relative;
      max-width: 500px;
      width: 100%;
    }

    .button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .cancel-button,
    .ok-button {
      margin: 0 10px;
      padding: 10px 20px;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease;
    }

    .cancel-button {
      background-color: #f44336;
      color: white;
    }

    .cancel-button:hover {
      background-color: #d32f2f;
    }

    .ok-button {
      background-color: #4caf50;
      color: white;
    }

    .ok-button:hover {
      background-color: #388e3c;
    }

    .no-shadow {
      box-shadow: none !important;
    }

    .v-dialog .v-dialog--active {
      box-shadow: none;
    }

    .v-dialog:not(.v-dialog--fullscreen) {
      max-height: 100%;
      box-shadow: none;
    }

    .main-container {
      padding: 16px;
      margin: 0 auto;
      margin-top: 1.5rem;
    }

    .show-on-map-button {
      background-color: black;
      color: white;
      padding: 6px 12px;
      border: none;
      font-size: small;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0px 2px 5px rgba(26, 115, 232, 0.4);
    }

    .show-on-map-button:hover {
      background-color: red;
    }

    .time-slot-container {
      border-radius: 10px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      margin-top: 1rem;
      margin-top: 1rem;
      padding: 1.5rem;
      transition: box-shadow 0.3s;

      &:hover {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
      }
    }

    .header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .availability-section {
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      padding: 20px;
      background-color: #fff;
      margin-top: 22px;
    }

    .availability-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .availability-title h4 {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 300;
      color: black;
    }

    .arrow-controls {
      display: flex;
      margin-right: -0.6rem;
    }

    .arrow-button {
      background: none;
      border: none;
      font-size: 1.5rem;
      color: #1a73e8;
      cursor: pointer;
      transition: color 0.3s;
      margin-left: 8px;
    }

    .arrow-button:disabled {
      color: #ccc;
      cursor: not-allowed;
    }
    @media (max-width: 500px) {
      .availability-title h4 {
        font-size: 0.7rem;
        font-weight: 300;
        white-space: pre;
        color: black;
      }
    }
  }
</style>
