<template>
  <div class="background">
    <Loader :loading="showLoader" />

    <!-- <v-toolbar color="#222222" dense flat>
      <img :src="logo" class="toolbar-icon" />
      <v-toolbar-title>Room Control POIs</v-toolbar-title>
    </v-toolbar> -->

    <v-toolbar class="custom-toolbar mb-4" flat dense>
      <v-btn icon>
        <v-icon size="40" @click="goBack">
          mdi-arrow-left-bold-circle
        </v-icon>
      </v-btn>
      <v-toolbar-title class="custom-toolbar-title">
        <!-- Align "Functions" title next to the logo -->
        {{ 'Room Control POIs' }}
      </v-toolbar-title>
      <img :src="logo" class="toolbar-icon" />
    </v-toolbar>

    <v-container>
      <v-row class="fill-height" align="center" justify="center">
        <!-- Card rendering with v-for -->
        <v-col v-for="(poi, index) in pois" :key="index" cols="12" sm="8" md="6" lg="3" xl="3">
          <v-card class="d-flex flex-column room-card" @click="goToRommControl(poi)">
            <!-- Title and Subtitle -->
            <v-card-title class="justify-space-between px-4 card-content">
              <div>
                <div class="text-h6 card-title">
                  {{ poi.name }}
                </div>
                <div class="text-caption card-subtitle">
                  {{ poi?.floor?.name + ', Floor # ' + poi?.floor?.number }}
                </div>
                <div class="text-caption card-category">
                  {{ poi?.category?.name }}
                </div>
              </div>
              <!-- Card Header with Avatar on right -->
              <v-avatar size="48" color="grey darken-3" v-if="poi.image">
                <v-img size="24" :src="poi.image"></v-img>
              </v-avatar>
            </v-card-title>
            <!-- Icons for hardware -->
            <v-card-text class="d-flex justify-start px-4 card-content">
              <v-btn v-for="hardware in lodash.uniqBy(poi.hardwares, 'type')" :key="hardware" icon
                style="box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2)" class="ma-1">
                <v-icon color="black" v-if="hardware.type === 'LIGHT'">
                  {{ 'mdi-lightbulb-on-outline' }}
                </v-icon>
                <v-icon color="black" v-if="hardware.type === 'BLINDS'">
                  {{ 'mdi-blinds' }}
                </v-icon>
                <v-icon color="black" v-if="hardware.type === 'VENTILATOR'">
                  {{ 'mdi-fan' }}
                </v-icon>
                <v-icon color="black" v-if="hardware.type === 'HEATER'">
                  {{ 'mdi-thermometer' }}
                </v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, onUnmounted } from 'vue'
import { getPois, getBuildings, getFile, getDataFromBlob, getFloorById, getPoiCategoryById, isStaingOrQa } from '@/controllers/BaseController.js'
import { getStoredItem, setStoredItem, removeStoredItem } from '@/services/utils.js'
import lodash from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getPrimaryColor } from '@/assets/Branding/branding.js'
import { getHardwareByType } from '@/controllers/BackboneAPI'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'RoomControlView',
  components: {
    Loader,
  },
  setup() {
    const logo = ref(getToolbarLogoImage())
    const showLoader = ref(false)
    const pois = ref([])
    const primaryColor = ref('white')
    const router = useRouter()
    let isPiaProject = isStaingOrQa()

    const goToRommControl = async poi => {
      try {
        poi = JSON.parse(JSON.stringify(poi))
        let allPois = JSON.parse(JSON.stringify(pois.value))
        poi = allPois.find(p => p.id === poi.id)
        const poiId = poi.id

        await setStoredItem('selectedHardwares', poi.hardwares) // Store the hardwares array
        router.push({
          name: 'RoomControl',
          params: { poi_id: poiId },
        })
      } catch (error) {
        console.error('Error storing hardwares:', error)
      }
    }

    const goBack = () => {
      window.history.back()
    }

    onMounted(async () => {
      showLoader.value = true
      try {
        const baseColor = getPrimaryColor()
        logo.value = await getToolbarLogoImage()
        primaryColor.value = baseColor

        const [buildingsData, poisData] = await Promise.all([getBuildings(), getPois()])

        const storedBuildingName = await getStoredItem('selectedBuilding')
        const storedBuilding = buildingsData.find(building => building.name === storedBuildingName)

        if (!storedBuilding) {
          throw new Error('Stored building not found')
        }

        let buildingPois = poisData.filter(poi => poi.buildingId === storedBuilding.id && poi.configs?.['room-control'] === 'true')

        const hardwareTypes = ['BLINDS', 'VENTILATOR', 'LIGHT', 'HEATER']
        const hardwarePromises = hardwareTypes.map(type => getHardwareByType(storedBuilding.id, type).catch(() => []))

        const hardwareArrays = await Promise.all(hardwarePromises)
        const hardwareData = Object.fromEntries(hardwareTypes.map((type, index) => [type, hardwareArrays[index]]))

        const poisWithDetailsPromises = buildingPois.map(async poi => {
          if (!poi.poiCategories?.ids?.[0]) return null

          const [imageBlob, floor, category] = await Promise.all([
            poi.files.entities.find(file => ['image', 'logo'].includes(file.type))?.id &&
            getFile(poi.files.entities.find(file => ['image', 'logo'].includes(file.type)).id),
            poi.floors?.ids?.[0] && getFloorById(poi.floors.ids[0]),
            getPoiCategoryById(poi.poiCategories.ids[0]),
          ])

          const image = imageBlob ? await getDataFromBlob(imageBlob) : null
          const hardwares = hardwareTypes.flatMap(type =>
            hardwareData[type].filter(hardware => hardware.configs?.['poi-id'] === poi.id).map(hardware => ({ type, id: hardware.id }))
          )

          return {
            ...poi,
            image,
            floor,
            category,
            building: storedBuilding,
            primaryColor: baseColor,
            hardwares,
          }
        })

        const processedPois = (await Promise.all(poisWithDetailsPromises)).filter(Boolean)

        pois.value = processedPois.sort((a, b) => {
          const aIsNaN = isNaN(a.name[0])
          const bIsNaN = isNaN(b.name[0])
          if (aIsNaN === bIsNaN) {
            return a.name.localeCompare(b.name, undefined, { numeric: true })
          }
          return aIsNaN - bIsNaN
        })
      } catch (error) {
        console.error('Error processing POIs:', error)
      } finally {
        showLoader.value = false
      }
    })

    return { logo, pois, showLoader, primaryColor, lodash, goToRommControl, goBack }
  },
})
</script>

<style scoped>
.background {
  background-color: white;
  padding-bottom: 100px;
  padding: 1rem;
}

.custom-toolbar {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.custom-toolbar-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  margin-left: 10px;
  /* Spacing between logo and title */
}

.toolbar-icon {
  height: 30px;
}

.room-card {
  background-color: #333333;
  color: #ffffff;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-content {
  background: floralwhite;
}

.card-title {
  color: black;
  font-weight: bold;
  white-space: break-spaces;
}

.card-subtitle {
  font-family: monospace;
  color: var(--v-primary-base, #6200ea);
}

.card-category {
  color: crimson;
  font-family: 'Material Design Icons';
}

.text-h5,
.text-caption {
  color: black;
}

.v-btn {
  margin: 0;
  padding: 0;
}

.v-icon {
  color: black;
}

.v-avatar {
  background-color: #666666;
}

.room-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 599px) {
  .room-card {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (min-width: 600px) {
  .room-card {
    width: auto;
    margin: 8px;
  }
}
</style>
