<template>
    <div class="roles-list">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Roles' }}
            </v-toolbar-title>
            <v-btn class="mr-1" icon small @click="refresh">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="CreateEditRole" color="black">Create
                new</v-btn>
        </v-toolbar>

        <v-container class="mt-2 mb-0" style="background-color: transparent; box-shadow: none">
            <v-card-text>
                <v-row align="center" class="mb-2">
                    <v-col cols="auto">
                        <!-- <v-btn icon @click="menu = !menu" color="primary" style="margin-top: -1rem">
                            <v-icon>{{ menu ? 'mdi-menu-open' : 'mdi-menu' }}</v-icon>
                        </v-btn> -->
                    </v-col>
                    <v-col>
                        <v-text-field v-model="searchQuery" label="Search" variant="outlined"
                            prepend-inner-icon="mdi-magnify" dense class="mx-4" style="margin-bottom: 0"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-container>
    </div>
</template>

<script>
import { ref, onMounted, defineComponent } from 'vue'
import { debounce } from 'lodash'
import Loader from '@/components/general/Loader.vue'
import { getToolbarLogoImage } from '@/assets/Branding/branding.js'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'RolesList',
    components: {
        Loader,
    },
    setup() {
        const showLoader = ref(true)
        const router = useRouter()
        const logo = ref('')

        const goBack = () => {
            window.history.back()
        }

        const navigateTo = (routeName, params = {}) => {
            router.push({ name: routeName, params })
        }

        const CreateEditRole = () => {
            navigateTo('new-role')
        }

        const resizeListener = debounce(() => (window.innerWidth), 200)

        onMounted(async () => {
            try {
                showLoader.value = true
                logo.value = await getToolbarLogoImage()
                window.addEventListener('resize', resizeListener)
                resizeListener()
                showLoader.value = false
            } catch (error) {
                console.error('Failed to load data:', error)
                showLoader.value = false
            }
        })

        return {
            showLoader,
            logo,
            router,
            goBack,
            navigateTo,
            CreateEditRole,
        }
    },
})
</script>

<style scoped>
.roles-list {
    padding: 1rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}
</style>